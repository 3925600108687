<template>
    <div>
        <div>
            <slot name="prepend"></slot>
        </div>
        <h3 class="text-white pos-r font-inter-bold d-inline" :class="{'text-2x':size ==='lg'}">
            <span class="ml--2" @click="$router.go(-1)" v-if="backBtn">
                <img src="../../assets/mobile/home/chevron-right.png" class="rotate-180" alt="">
            </span>
            {{ heading }}<span class="bullet">
        </span></h3>
        <section class="fs--1 text-2">
            <slot name="info"></slot>
        </section>
    </div>
</template>

<script>
export default {
    name: 'LoginHeading',
    props: {
        heading: {
            type: String
        },
        size: {
            type: String,
            default: 'sm'
        },
        backBtn: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped lang="scss">
.bullet {
    position: absolute;
    //right: 105px;
    //bottom: 5px;
    //right: 36%;
    right: -11px;
    bottom: 14%;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background-color: var(--color-secondary);
}
</style>
