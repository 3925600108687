<template>
    <section class="pt-8">
        <div class="container full-height p-4">
            <login-heading :back-btn="true" class="mb-5" heading="KYC Documents" size="lg">
            </login-heading>
            <div class="row">
                <div class="col-12 col-lg-4 col-md-6">
                    <img src="../../../assets/web/avoid-captcha.jpg" class="w-50 mb-3" alt="">
                    <validated-input placeholder="Enter the code above here" class="c-input mb-5"></validated-input>
                    <validated-checkbox type="checkbox" label="I agree that i have read and accepted the" class="d-inline text-white mb-0">
                    </validated-checkbox>
                    <validated-checkbox type="checkbox" label="I may receive communications from Bank e Auctions India.com" class="d-inline text-white">
                    </validated-checkbox>
                    <span class="text-primary d-inline"><router-link class="d-inline" to="/">User Agreement and Privacy Policy</router-link></span>
                    <btn block text="Register" @click="registerUser" class="mt-4 border-r--2 auth-button py-3"></btn>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
import LoginHeading from '@components/login/LoginHeading';

export default {
    name: 'KycDocuments',
    components: { LoginHeading },
    methods: {
        registerUser () {
            this.$router.push({ name: 'RegisterSuccessful' });
        }
    }
};
</script>

<style scoped>

</style>
